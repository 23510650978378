import moment from "moment";
import { DATEFORMAT } from "../config/constant";

// constants for slot planning
const slotDuration = "90";
const breakDuration = "60";

export const slotOptions = [
  { label: "0", value: "0" },
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
  { label: "6", value: "6" },
];

export const status = ["AVAILABLE", "ALLOTED", "ONHOLD"];

export const oneDaySlotPlanning = (
  startTime,
  slotsBeforeBreak,
  slotsAfterBreak,
  status,
  dockData
) => {
  const columns = [];
  const singleBooking = {};
  const slotObject = {};
  const afterBreakSlotObject = {};
  const finalResult = [];
  const dockColumn = 1;
  const breakColumn = 1;
  const totalColumns =
    dockColumn + slotsBeforeBreak + breakColumn + slotsAfterBreak;

  singleBooking["id"] = 0;
  singleBooking["dock"] = "Dock1";

  // generating slots before break
  for (let i = 0; i < slotsBeforeBreak; i++) {
    const tempSlot = "slot" + (i + 1);

    slotObject["from"] = startTime;
    const tempTime = startTime;
    slotObject["to"] = moment(tempTime, "HH:mm")
      .add(slotDuration, "minutes")
      .format("HH:mm");

    slotObject["status"] = status[0];

    const newSlot = { ...slotObject };

    singleBooking[tempSlot] = newSlot;
    startTime = moment(startTime, "HH:mm")
      .add(slotDuration, "minutes")
      .format("HH:mm");
  }

  // generating break
  const breakStart = startTime;
  startTime = moment(startTime, "HH:mm")
    .add(breakDuration, "minutes")
    .format("HH:mm");

  slotObject["from"] = breakStart;
  slotObject["to"] = startTime;

  singleBooking["break"] = slotObject;

  // generating slots after break
  for (let i = 0; i < slotsAfterBreak; i++) {
    const tempSlotsBeforeBreak = slotsBeforeBreak;
    const tempSlotAfterBreak =
      "slot" + (parseInt(tempSlotsBeforeBreak) + (i + 1));

    afterBreakSlotObject["from"] = startTime;
    const tempTime = startTime;
    afterBreakSlotObject["to"] = moment(tempTime, "HH:mm")
      .add(slotDuration, "minutes")
      .format("HH:mm");

    afterBreakSlotObject["status"] = status[0];

    const newSlot = { ...afterBreakSlotObject };
    singleBooking[tempSlotAfterBreak] = newSlot;
    startTime = moment(startTime, "HH:mm")
      .add(slotDuration, "minutes")
      .format("HH:mm");
  }

  // generating array of slots
  for (let j = 0; j < dockData.length; j++) {
    const newDock = JSON.parse(JSON.stringify(singleBooking));

    newDock["id"] = j + 1;
    newDock["dock"] = dockData[j].dockNo;
    newDock["dockId"] = dockData[j].dockId;

    finalResult.push(newDock);
  }

  // generating array of columns
  for (let i = 1; i <= totalColumns; i++) {
    let accessorValue = "";
    if (i === 1) {
      accessorValue = Object.keys(singleBooking)[i];
    } else {
      accessorValue =
        Object.keys(singleBooking)[i] + "." + Object.keys(slotObject)[0];
    }

    const headerValue = Object.keys(singleBooking)[i];
    columns.push({
      field: accessorValue,
      header: headerValue,
    });
  }
  // console.log("One day", finalResult);
  return finalResult;
};

export const generateWeekData = (datesOfTheWeek, slots) => {
  const weekArray = [];
  const dayObject = {};
  // dataForTheWeek;
  dayObject["date"] = "";
  dayObject["slotPlanningData"] = null;

  for (let j = 0; j < datesOfTheWeek.length; j++) {
    const newDayObject = JSON.parse(JSON.stringify(dayObject));

    const newSlots = JSON.parse(JSON.stringify(slots));

    newDayObject["date"] = datesOfTheWeek[j];
    newDayObject["slotPlanningData"] = newSlots;

    weekArray.push(newDayObject);
  }
  return weekArray;
};

export const getSlotPlanningJson = (
  selectedFacilty,
  dataForTheWeek,
  status
) => {
  const weekDataJson = {};
  const individualDayData = {};
  let slot = {};
  const tempListOfDaysSlots = [];
  weekDataJson["facilityId"] = selectedFacilty;
  weekDataJson["listOfDaysSlots"] = [];

  console.log("Data for the week", dataForTheWeek);

  // Looping through individual days of week
  for (let k = 0; k < dataForTheWeek.length; k++) {
    const activeSlotData = dataForTheWeek[k].slotPlanningData;
    const activeDate = dataForTheWeek[k].date;
    const individualDaySlots = [];

    // Looping through individual dock
    for (let i = 0; i < Object.keys(activeSlotData[0]).length; i++) {
      // Filtering slot objects only
      if (Object.keys(activeSlotData[0])[i].includes("slot")) {
        const keys = Object.keys(activeSlotData[0])[i];
        const docks = [];
        for (let j = 0; j < activeSlotData.length; j++) {
          // Filtering Available and Booked slots
          // if (activeSlotData[j][keys].status != status[2]) {
          const dock = {};
          dock["dockId"] = parseInt(activeSlotData[j]["dockId"]);
          dock["dockStatus"] = activeSlotData[j][keys].status;

          const newDock = JSON.parse(JSON.stringify(dock));

          // Adding docks into docks array
          docks.push(newDock);
          slot["fromTime"] =
            activeDate +
            // .replaceAll("-", ".")
            " " +
            activeSlotData[j][keys].from;
          slot["toTime"] =
            activeDate +
            // .replaceAll("-", ".")
            " " +
            activeSlotData[j][keys].to;
          slot["slotDocks"] = docks;
          // }
        }

        // Adding objects into individualDaySlots array
        if (Object.keys(slot).length !== 0) {
          const newSlot = JSON.parse(JSON.stringify(slot));
          individualDaySlots.push(newSlot);
        }
        slot = {};
      }
    }
    individualDayData["planningDate"] = dataForTheWeek[k].date;

    individualDayData["slots"] = individualDaySlots;

    // Generating data for prebooking
    const preBookingsDataForDay = [];
    // Looping through data of active day
    for (let l = 0; l < activeSlotData.length; l++) {
      const slotsArray = [];

      // console.log("Data", activeSlotData[l]);
      // Looping through objects of active day
      const allKeys = Object.keys(activeSlotData[l]);
      // console.log("allKey", allKeys);
      for (let key = 0; key < allKeys.length; key++) {
        if (
          allKeys[key] !== "dock" &&
          allKeys[key] !== "id" &&
          allKeys[key] !== "break" &&
          allKeys[key] !== "dockId"
        ) {
          slotsArray.push(allKeys[key]);
        }
      }

      // let oneDockArray = Object.keys(activeSlotData[l]);
      const dockPrebooking = [];
      for (let sa = 0; sa < slotsArray.length; sa++) {
        const slotDetail = activeSlotData[l][slotsArray[sa]];
        if (slotDetail.status === status[1]) {
          // console.log("single slots", activeSlotData[l][slotsArray[sa]]);
          if (dockPrebooking.length === 0) {
            const slotTiming = {
              fromTime: activeDate + " " + slotDetail.from,
              toTime: activeDate + " " + slotDetail.to,
            };

            const newPrebookingDetails = slotDetail.preBookingDetails;
            newPrebookingDetails["slots"] = [slotTiming];
            newPrebookingDetails["dockNo"] = activeSlotData[l]["dock"];
            newPrebookingDetails["dockId"] = parseInt(
              activeSlotData[l]["dockId"]
            );
            dockPrebooking.push(newPrebookingDetails);
            // console.log("New prebooking details", newPrebookingDetails);
          } else {
            const index = dockPrebooking.findIndex(
              (pb) =>
                pb.vehicleLicensePlateNumber ===
                slotDetail.preBookingDetails.vehicleLicensePlateNumber
            );
            // console.log("Index", index);
            if (index > -1) {
              const slotTiming = {
                fromTime: activeDate + " " + slotDetail.from,
                toTime: activeDate + " " + slotDetail.to,
              };
              dockPrebooking[index]["slots"].push(slotTiming);
            } else {
              const slotTiming = {
                fromTime: activeDate + " " + slotDetail.from,
                toTime: activeDate + " " + slotDetail.to,
              };

              const newPrebookingDetails = slotDetail.preBookingDetails;
              newPrebookingDetails["slots"] = [slotTiming];
              newPrebookingDetails["dockNo"] = activeSlotData[l]["dock"];
              newPrebookingDetails["dockId"] = parseInt(
                activeSlotData[l]["dockId"]
              );
              dockPrebooking.push(newPrebookingDetails);
              // console.log("again adding new prebooking", newPrebookingDetails);
            }
            // console.log("dock prebook---", dockPrebooking);
          }
        }
      }
      // console.log("dock prebook---", dockPrebooking);
      if (dockPrebooking.length > 0) {
        dockPrebooking.map((e) => {
          preBookingsDataForDay.push(e);
        });
      }

      // console.log("One dock array", oneDockArray);
    }

    // individualDayData["preBookings"] = preBookingsDataForDay;
    individualDayData["preBookings"] = preBookingsDataForDay;
    const newIndividualDayData = JSON.parse(JSON.stringify(individualDayData));
    tempListOfDaysSlots.push(newIndividualDayData);
  }

  // Removing empty prebooking objects
  for (let s = 0; s < tempListOfDaysSlots.length; s++) {
    if (tempListOfDaysSlots[s].preBookings.length === 0) {
      delete tempListOfDaysSlots[s]["preBookings"];
    }
  }
  weekDataJson["listOfDaysSlots"] = tempListOfDaysSlots;
  console.log("week data json----->", weekDataJson);

  // Converting objects into JSON
  // let finalSlotPlanningJson = JSON.stringify(weekDataJson);
  // console.log("Final Slot Planning JSON----->", finalSlotPlanningJson);

  return weekDataJson;
};
